import React from "react"
import Layout from '../components/layout';
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <Layout>
      <div className='page-404'>
        <h1>Page not found</h1>
        <p>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          whatever you're looking for isn't here. I hope you find it.
          <br />
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
